import { first, second, third } from '../../assets/howitworks-page'

import { motion, useScroll, useTransform } from 'framer-motion'
import { useRef } from 'react'

export function HowItWorks() {
  const targetRef = useRef(null)
  const { scrollYProgress } = useScroll({
    target: targetRef,
  })
  const x = useTransform(scrollYProgress, [0, 1], ['1%', '-100%'])

  return (
    <section id="howitworks" ref={targetRef} className="relative h-[300vh]">
      <div className="sticky top-0 flex h-screen w-screen items-center overflow-hidden md:left-[12.5%] md:w-3/4">
        <motion.div style={{ x }} className="flex gap-4">
          {cards.map((card) => {
            return <Card card={card} key={card.id} />
          })}
        </motion.div>
      </div>
    </section>
  )
}

const Card = ({ card }) => {
  return (
    <>
      <div
        key={card.id}
        className="group relative mt-10 h-[270px] w-[250px] overflow-hidden bg-neutral-200 md:mt-0 md:h-[450px] md:w-[450px]"
      >
        <div
          style={{
            backgroundImage: `url(${card.url})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          className="absolute inset-0 z-0 transition-transform duration-300 group-hover:scale-110"
        ></div>
      </div>
      <div className="mx-28 flex w-[300px] items-center justify-center text-justify text-zinc-600 md:w-[400px]">
        <p className="text-sm md:text-base">{card.text}</p>
      </div>
    </>
  )
}

const cards = [
  {
    url: first,
    text: 'A terapia oferece abordagens eficazes para o cuidado psicológico. Na terapia presencial, o contato do paciente será pessoal e direto, proporcionando uma conexão imersiva. Por outro lado, na terapia online, as sessões são realizadas por videochamada, permitindo flexibilidade geográfica e acesso conveniente. Ambas as modalidades proporcionam um ambiente seguro para expressar emoções, discutir desafios e desenvolver estratégias para o bem-estar mental. Escolher entre terapia presencial ou online depende das suas preferências pessoais e comodidade, garantindo que todos tenham acesso aos benefícios transformadores da psicoterapia.',
    id: 1,
  },
  {
    url: second,
    text: 'Descubra o poder transformador da terapia! Ao investir em seu bem-estar emocional, você fortalece a capacidade de enfrentar desafios, desenvolver autoconhecimento e conquistar uma vida mais equilibrada. Minha abordagem centrada no paciente, visa oferecer um espaço seguro e acolhedor para explorar suas emoções e encontrar soluções construtivas.',
    id: 2,
  },
  {
    url: third,
    text: 'Juntos, podemos construir caminhos para o crescimento pessoal e a realização de objetivos. Dê o primeiro passo para uma vida mais saudável e plena, permita-se a experiência revitalizante da terapia. Estou aqui para apoiá-lo nessa jornada. Atendimento psicológico em todo o Brasil, de acordo com sua necessidade, proporcionando flexibilidade de horários para se ajustar à sua rotina.',
    id: 3,
  },
]
