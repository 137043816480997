import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import { faqBg } from '../../assets/faq-page/index'

export function Faq() {
  const faqData = [
    {
      question: 'Quais as principais demandas atendidas?',
      answer:
        'Ansiedade generalizada, síndrome do pânico, fobia social, compulsão alimentar, transtorno depressivo, bipolaridade, baixa autoestima, luto, autoconhecimento, traumas, e outros transtornos correlacionados.',
    },
    {
      question:
        'Como funciona e qual o valor da terapia individual online e presencial?',
      answer:
        'Terapia online para adultos e idosos em todo o Brasil. Terapia presencial para adultos e idosos na cidade de Goiânia - GO. Espaço sigiloso, sessões agendadas, com duração de 50 minutos a uma hora, valor de 280,00 reais. Conforme o progresso, as sessões podem se tornar menos frequentes, passando para encontros quinzenais e depois mensais, até que seja possível efetuar a alta do paciente, de acordo com o seu progresso terapêutico. A duração da terapia pode variar de semanas a meses, ou até mais, dependendo da complexidade de cada caso, conforme a evolução individual e o empenho do paciente.',
    },
    {
      question: 'Por quanto tempo terei que fazer as consultas?',
      answer:
        'O tempo necessário para as consultas pode variar dependendo das suas necessidades e objetivos terapêuticos. Alguns clientes podem se beneficiar de um curto período de terapia para resolver questões específicas, enquanto outros podem preferir um acompanhamento mais prolongado para trabalhar em problemas mais complexos. Juntos, podemos discutir e ajustar a frequência e a duração das sessões de acordo com o seu progresso.',
    },
    {
      question: 'Aceitamos algum plano de saúde?',
      answer:
        'Como psicóloga privada, não aceitamos planos de saúde. Isso se deve principalmente a necessidade de manter um nível de independência funcional na prática clínica.',
    },
    {
      question: 'Posso ser atendido(a) pelo celular?',
      answer:
        'Sim, é possível ser atendido pelo celular. Com a tecnologia atual, sessões de terapia online são feitas preferencialmente por desktop ou notebook, por meio do aplicativo googlemeet. No entanto, as sessões poderão ser realizadas também pelo celular, mediante vídeo-chamada whatsapp/googlemeet, tendo como suporte mensagens/áudios para proporcionar conveniência e acessibilidade ao paciente. A terapia pelo celular pode oferecer o mesmo nível de apoio e eficácia que as sessões presenciais, permitindo uma conexão terapêutica significativa e uma abordagem personalizada para alcançar seus objetivos de saúde mental.',
    },
    {
      question: 'Minhas informações são mantidas em sigilo?',
      answer:
        'Sim, como psicóloga, sou legalmente obrigada a manter suas informações em sigilo, exceto em situações específicas, como ameaça iminente à vida, abuso infantil ou ordem judicial. O sigilo é uma prioridade, sendo fundamental para construir uma relação de confiança na terapia.',
    },
    {
      question: 'Fazendo as consultas online você conseguirá me diagnosticar?',
      answer:
        'Sim, por meio de consultas online, posso conduzir uma avaliação clínica abrangente e fazer um diagnóstico, da mesma forma que em uma consulta presencial. No entanto, é importante ressaltar que um diagnóstico preciso pode exigir informações adicionais, como histórico médico e familiar. O formato online pode afetar a dinâmica da interação, mas ainda assim é possível realizar uma avaliação cuidadosa.',
    },
    {
      question:
        'Como posso ter certeza de que esta é uma forma eficaz de terapia?',
      answer:
        'A eficácia desta abordagem depende de vários fatores, incluindo a qualidade da relação terapêutica, a disposição do paciente no processo e as técnicas utilizadas. Estudos mostram consistentemente que terapia cognitiva-comportamental é altamente eficaz na redução de doenças psicossomáticas, melhoria do bem-estar emocional e desenvolvimento de habilidades de enfrentamento.',
    },
    {
      question: 'Qual é a diferença entre um psicólogo e um psiquiatra?',
      answer:
        'Psicólogo: é um profissional com formação em psicologia, tendo um amplo conhecimento em psicoterapia, avaliação psicológica, suporte e técnicas de intervenção psicológica. Sua abordagem é voltada para os aspectos emocionais, comportamentais e cognitivos do paciente. Psiquiatra: é um médico que aborda os problemas mentais a partir de uma perspectiva médica, podendo prescrever medicamentos e realizar exames físicos e neurológicos, tem um enfoque mais biológico no tratamento dos transtornos mentais.  Em muitos casos, pode ser recomendável a combinação dos dois profissionais para um melhor resultado e qualidade de vida do paciente.',
    },
  ]

  return (
    <section id="faq">
      <div
        style={{
          backgroundImage: `url(${faqBg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
        className="flex h-full flex-col items-center justify-center text-2xl"
      >
        <div className="rounded-2xl border-2 px-16 py-8 backdrop-blur-xl">
          <div className="flex w-auto flex-col space-y-8 transition-all md:w-96 xl:w-[900px]">
            <Accordion type="single" collapsible>
              {faqData.map((item, index) => (
                <AccordionItem key={index} value={`item-${index + 1}`}>
                  <AccordionTrigger>
                    <p className="text-base">{item.question}</p>
                  </AccordionTrigger>
                  <AccordionContent>
                    <p className="text-sm md:text-base">{item.answer}</p>
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  )
}
