import { useGSAP } from '@gsap/react'
import { gsap } from 'gsap'
import { useEffect, useRef } from 'react'
import { brain, key, lock } from '../../assets/loading-animation/index'

gsap.registerPlugin(useGSAP)

export function Loading() {
  const loading = useRef()
  const tl = useRef()

  useEffect(() => {
    gsap.set(loading.current, {
      visibility: 'visible',
      overflow: 'hidden',
    })
    ;(tl.current = gsap
      .timeline({ defaults: { ease: 'power4.inOut' } })
      .to('.brain', {
        opacity: 1,
        duration: 2,
        width: '66.666667%',
        height: '66.666667%',
      })
      .to('.lock', { opacity: 1, duration: 1 })
      .to('.key', { opacity: 1, duration: 1.5, x: '45%', ease: 'power3.out' })
      .to('.text-key', { opacity: 1, duration: 1 })
      .to('.text-key', {
        opacity: 0,
        display: 'none',
        duration: 1,
      })
      .to(
        '.loading',
        {
          opacity: 0,
          scale: 0.1,
          y: '-45%',
          duration: 1,
        },
        '<',
      )
      .to('.loading', {
        visibility: 'hidden',
        opacity: 0,
        display: 'none',
        width: 0,
        height: 0,
        overflow: 'hidden',
      })),
      tl.current.play()
  }, [])

  return (
    <>
      {/* <!--========= LOADING SCREEN =========--> */}
      <section>
        <div
          className="loading opacity-1 bg-withe pointer-events-none flex h-screen w-screen select-none items-center justify-center"
          ref={loading}
        >
          <img
            className="brain h-1/12 fixed w-1/12 opacity-0"
            src={brain}
            alt="Cérebro"
          />
          <img
            className="lock fixed top-[44.5%] h-[4%] w-[4%] opacity-0 transition-all duration-500 ease-in-out sm:top-[37%] md:top-[37%] md:h-[10%] md:w-[10%] lg:top-[30%] lg:h-[15%] lg:w-[15%]"
            src={lock}
            alt="Fechadura"
          />
          <img
            className="key fixed top-[17.5%] h-[56%] w-[56%] max-w-[30%] translate-x-full opacity-0 transition-all sm:top-[12.5%] sm:w-[40.3%] md:top-[11.4%] lg:top-[6%]"
            src={key}
            alt="Chave"
          />
          <p className="text-key absolute top-[60%] font-dancing text-xl text-gray-500 opacity-0 sm:top-[48%] md:top-[75%]">
            liberte-se
          </p>
        </div>
      </section>
    </>
  )
}
