import { CardContent } from '@/components/ui/card'
import { useState } from 'react'
import { group, pix } from '../../assets/groups-page'

export function Group() {
  const [isFlipped, setIsFlipped] = useState(false)

  const handleCardClick = () => {
    setIsFlipped(!isFlipped)
  }

  return (
    <section className="mx-auto mt-80 flex h-full select-none flex-col items-center justify-center text-center text-zinc-600 transition-all">
      <div
        className={`card-container ${isFlipped ? 'flipped' : ''}`}
        onClick={handleCardClick}
      >
        <div className="card">
          <div className="card-face card-face-front">
            <CardContent className="space-y-4">
              <img
                src={group}
                alt="Logo integrada a um corpo em ilustração"
                width={500}
                className="flex items-center justify-center pt-10"
              />
            </CardContent>
          </div>
          <div className="card-face card-face-back">
            <CardContent className="space-y-4">
              <p className="space-y-10 text-zinc-600">
                <strong>
                  Como funciona e qual o valor da terapia em grupo online?
                </strong>
                <br />
                <p className="text-justify text-base">
                  Terapia com quatro encontros semanais, ao vivo, no valor de
                  500 reais, com duração de 1 hora e trinta minutos cada um.
                  Grupo limitado de 5 a 8 pessoas, sala online e ao vivo pelo
                  google Meet, exclusivo para adultos. Teremos um grupo fechado
                  no Whatsapp onde irei disponibilizar o link dos encontros, os
                  recados, as informações e compartilhamentos de conteúdo.
                </p>
              </p>
              <p>Pagamentos somente via:</p>
              <img
                src={pix}
                alt="Logo integrada a um corpo em ilustração"
                width={24}
                className="mx-auto flex w-48 select-none items-center justify-center transition-all"
              />
            </CardContent>
          </div>
        </div>
      </div>
    </section>
  )
}
