import { Button } from '@/components/ui/button'
import { Link } from 'react-router-dom'
import { notFound } from '../../assets/notfound-page'
import { Header } from '../Header'
import { Footer } from '../Footer'

export function NotFound() {
  return (
    <>
      <Header />
      <div className="m-14 ml-14 flex h-screen flex-col items-center justify-center text-center">
        <p className="font-sora w-full p-2 font-light text-primary">
          Parece que você se perdeu
        </p>
        <p className="font-sora w-full p-2 text-4xl font-black text-primary">
          Página não encontrada!
        </p>
        <img
          src={notFound}
          alt="Página não encontrada!"
          className="mt-8"
          width={600}
          height={550}
        />
        <Link to="/">
          <Button variant="destructive" size="lg" className="mt-8">
            Voltar para página principal
          </Button>
        </Link>
      </div>
      <Footer />
    </>
  )
}
