import { CirclePlay } from 'lucide-react'
import React, { useRef, useState } from 'react'
import { music } from '../../assets/about-page/index'
import { Button } from '@/components/ui/button'

export function MusicPlayer() {
  const [isPlaying, setIsPlaying] = useState(false)
  const audioRef = useRef(new Audio(music))

  const togglePlay = () => {
    const audio = audioRef.current
    if (isPlaying) {
      audio.pause()
    } else {
      audio.play()
    }
    setIsPlaying(!isPlaying)
  }

  return (
    <div className="mt-10 flex items-center justify-center align-middle">
      <Button
        onClick={togglePlay}
        className="bg-[#ABE3E1] text-black transition-all ease-in-out hover:bg-[#009589]"
      >
        {isPlaying ? 'Pausar' : 'Tocar música de fundo'}
        <CirclePlay className="ml-2" />
      </Button>
    </div>
  )
}
