import { logo } from '../../assets/landing-page/index'

export function Footer() {
  return (
    <div className="flex h-[400px] w-full items-center justify-center bg-gradient-to-tr from-[#E3FBF8] from-30% to-[#FFE9FF] to-100% text-2xl">
      <footer className="flex h-3/4 w-3/4 justify-center">
        <ul className="flex flex-col items-center justify-center space-y-4">
          <li>
            <a
              href="/about"
              className="flex text-wrap text-zinc-700 sm:text-nowrap"
            >
              Sobre mim
            </a>
          </li>
          <li>
            <a
              href="/#iwanttostart"
              className="flex text-wrap text-zinc-700 sm:text-nowrap"
            >
              Quero começar
            </a>
          </li>
          <li>
            <a
              href="/#howitworks"
              className="flex text-wrap text-zinc-700 sm:text-nowrap"
            >
              Como funciona
            </a>
          </li>
          <li>
            <a
              href="/#faq"
              className="flex text-wrap text-zinc-700 sm:text-nowrap"
            >
              Perguntas frequentes
            </a>
          </li>
        </ul>
        <div className="mx-4 h-full w-px bg-slate-400 md:mx-14"></div>
        <div className="flex flex-col items-end justify-end">
          <a href="/#hero">
            <button className="m-8">
              <img
                src={logo}
                alt="logo"
                className="pointer-events-none flex w-64 select-none items-center"
              />
            </button>
          </a>
          <a href="mailto:atendimento@bertavalente.com.br">
            <p className="text-center text-sm">
              Fale comigo no atendimento@bertavalente.com.br
            </p>
            <p className="mb-4 text-center text-sm">
              <a href="tel:+5562981390777">ou (62) 9 8139-0777</a>
            </p>
          </a>
          <p className="mb-4 text-xs">
            Copyright © 2024 Berta Valente. Todos os direitos reservados. CRP:
            09/16239
          </p>
          <a href="https://www.linkedin.com/in/kernelgm/">
            <p className="mb-4 text-xs">
              Feito com muito carinho por KernelGM ❤️
            </p>
          </a>
        </div>
      </footer>
    </div>
  )
}
