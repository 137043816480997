import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Mail, Send } from 'lucide-react'
import { useState } from 'react'

export default function JoinModal() {
  const [name, setName] = useState('')
  const [number, setNumber] = useState('')
  const [text, setText] = useState('')
  const [error, setError] = useState('')

  const isValidPhoneNumber = (phoneNumber) => {
    return /^\(?\d{2}\)? ?9?\d{4}-?\d{4}$/.test(phoneNumber)
  }

  const handleSendMessage = (sendMethod) => {
    if (!isValidPhoneNumber(number)) {
      setError(
        'Número de telefone inválido. Por favor, insira um número válido.',
      )
      return
    }

    const phoneNumber = '556281390777'
    const message = `Nome: ${name} \nCelular: ${number}\nDescrição do contato: ${text}`

    if (sendMethod === 'whatsapp') {
      const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
        message,
      )}`
      window.open(whatsappUrl, '_blank')
    } else if (sendMethod === 'email') {
      const emailSubject = encodeURIComponent('Assunto do e-mail')
      const emailBody = encodeURIComponent(message)
      const mailtoUrl = `mailto:email@example.com?subject=${emailSubject}&body=${emailBody}`
      window.location.href = mailtoUrl
    }
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="bg-[#ABE3E1] hover:bg-[#EBBFEC]">
          <p className="text-zinc-600">Quero começar!</p>
        </Button>
      </DialogTrigger>
      <DialogContent className="-mt-40 sm:max-w-[425px] md:-mt-0">
        <DialogHeader>
          <DialogTitle>Mande uma mensagem para mim 💬</DialogTitle>
          <DialogDescription className="pt-4">
            Por favor, forneça seu nome completo e número de telefone com DDD,
            juntamente com uma breve descrição do seu caso. Esta mensagem será
            enviada diretamente para o meu celular, e entrarei em contato com
            você o mais breve possível.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              Nome
            </Label>
            <Input
              id="name"
              placeholder="Seu nome ✍🏼"
              className="col-span-3"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="number" className="text-right">
              Celular
            </Label>
            <Input
              id="number"
              placeholder="(XX) 9 XXXX-XXXX 📞"
              className="col-span-3"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="text" className="text-right">
              Descrição do contato
            </Label>
            <Input
              id="text"
              placeholder="Deixe uma mensagem legal aqui. 😊"
              className="col-span-3"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </div>
          {error && <p className="text-red-500">{error}</p>}
        </div>
        <DialogFooter>
          <Button
            className="bg-[#ABE3E1] hover:bg-[#EBBFEC]"
            onClick={() => handleSendMessage('whatsapp')}
          >
            <p className="text-zinc-600">Enviar WhatsApp</p>
            <Send className="ml-2 text-zinc-600" />
          </Button>
          <Button
            className="bg-[#EBBFEC] hover:bg-[#ABE3E1]"
            onClick={() => handleSendMessage('email')}
          >
            <p className="text-zinc-600">Enviar e-mail</p>
            <Mail className="ml-2 text-zinc-600" />
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
