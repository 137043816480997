import { useGSAP } from '@gsap/react'
import { gsap } from 'gsap'
import { useEffect, useRef } from 'react'
import { logo } from '../../assets/landing-page/index'

gsap.registerPlugin(useGSAP)

export function Header() {
  const header = useRef()
  const tl = useRef()

  useEffect(() => {
    (tl.current = gsap
      .timeline({ defaults: { ease: 'power4.in' } })
      .to('.header', {
        opacity: 1,
        duration: 0.5,
        top: 0,
      })),
      tl.current.play()
  }, [])
  return (
    <>
      {/* <!--============= HEADER =============--> */}
      <header
        className="header fixed -top-96 z-50 ml-4 flex w-full items-center justify-center opacity-0 backdrop-blur-lg transition-all md:ml-0"
        ref={header}
      >
        <nav className="mt-2">
          <ul className="flex flex-row items-center justify-center space-x-4 md:space-x-10">
            <li className="hidden lg:block">
              <a href="/#hero">
                <button className="mr-24 pt-4">
                  <img
                    src={logo}
                    alt="logo"
                    className="pointer-events-none flex w-28 select-none items-center"
                  />
                </button>
              </a>
            </li>
            <li>
              <a
                href="/about"
                className="flex text-wrap text-zinc-700 sm:text-nowrap"
              >
                Sobre mim
              </a>
            </li>
            <li>
              <a
                href="/#iwanttostart"
                className="flex text-wrap text-zinc-700 sm:text-nowrap"
              >
                Quero começar
              </a>
            </li>
            <li>
              <a
                href="/#howitworks"
                className="flex text-wrap text-zinc-700 sm:text-nowrap"
              >
                Como funciona
              </a>
            </li>
            <li>
              <a
                href="/#faq"
                className="flex text-wrap text-zinc-700 sm:text-nowrap"
              >
                Perguntas frequentes
              </a>
            </li>
          </ul>
        </nav>
      </header>
    </>
  )
}
