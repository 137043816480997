import { useEffect, useRef } from 'react'
import { gsap } from 'gsap'

export function Hero() {
  const hero = useRef()
  const bgImage = useRef()

  useEffect(() => {
    gsap.set(hero.current, {
      opacity: 0,
    })
    gsap.set(bgImage.current, {
      opacity: 0,
    })

    const tl = gsap.timeline({ defaults: { ease: 'power4.in' } })
    tl.to(
      bgImage.current,
      {
        opacity: 0.15,
      },
      '<',
    ).to(hero.current, {
      opacity: 1,
    })
  }, [])

  return (
    <section id="hero">
      <div
        className="hero relative z-10 mx-[12.5%] flex h-screen w-3/4 select-none items-center justify-center text-2xl opacity-0 transition-all"
        ref={hero}
      >
        <p className="z-2 text-center text-zinc-600">
          Fique bem{' '}
          <span className="text-3xl text-[#ABE3E1] transition-all hover:text-[#009589]">
            hoje
          </span>
          ,<br />
          esteja pronto para o{' '}
          <span className="text-3xl text-[#EBBFEC] transition-all hover:text-[#D165D4]">
            amanhã
          </span>
          .
        </p>
      </div>

      <div
        className="bg-image absolute left-0 top-0 h-full w-full bg-cover bg-fixed bg-no-repeat opacity-0 transition-all"
        ref={bgImage}
      />
    </section>
  )
}
