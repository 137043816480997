import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Card, CardContent, CardHeader } from '@/components/ui/card'
import { useState } from 'react'
import { berta, presentation } from '../../assets/about-page'
import { Footer } from '../Footer'
import { Header } from '../Header'
import { MusicPlayer } from '../MusicPlayer'

export function About() {
  const [isPlaying, setIsPlaying] = useState(false)

  const togglePlayPause = () => {
    const video = document.getElementById('about-video')
    if (video.paused) {
      video.play()
      setIsPlaying(true)
    } else {
      video.pause()
      setIsPlaying(false)
    }
  }

  return (
    <>
      <Header />
      <div className="h-20 md:h-24 lg:h-40"></div>
      <div className="mx-[12.5%] flex h-full w-3/4 justify-center">
        <div className="flex flex-col items-center justify-center">
          <Card className="drop-shadow-xl">
            <CardHeader>
              <div className="flex items-center justify-center align-middle drop-shadow-2xl">
                <Avatar className="h-80 w-80">
                  <AvatarImage src={berta} alt="Foto de perfil" />
                  <AvatarFallback>Berta</AvatarFallback>
                </Avatar>
              </div>
            </CardHeader>
            <CardContent>
              <p className="mt-4 flex-col text-center text-2xl">
                <span className="text-3xl text-zinc-600 transition-all">
                  Quem é Berta Valente?
                </span>
              </p>
              <p className="mt-10 text-justify text-sm text-zinc-600 hover:text-black md:text-base">
                Sou psicóloga, apaixonada pela vida e por cada processo de
                evolução e autoconhecimento que seja vivenciado por cada
                indivíduo. Para melhor atender cada um dos meus pacientes,
                começo compreendendo suas histórias de vida e singularidades sem
                julgamento, com empatia, aceitação e uma escuta qualificada.
                Também sou pós-graduada em Terapia Cognitivo-Comportamental,
                Saúde Hospitalar e Psico-Oncologia, tendo experiência na
                aplicação de abordagens psicológicas, relativas aos pacientes
                internados em Unidade de Terapia Intensiva (UTI), bem como no
                suporte a seus familiares no contexto hospitalar. Além disso,
                possuo diversos cursos de extensão, metodologia e aprimoramento
                que contribuem para melhor atender cada ser humano, com suas
                subjetividades e demandas. Mesmo que os conflitos internos
                pareçam inevitáveis, juntos podemos iniciar essa transformação,
                uma jornada de autoconhecimento, equilíbrio e estruturação
                emocional. Aliás, compreende-se que muitas vezes, uma única
                abordagem não é satisfatória para atender as demandas de cada
                paciente. Com isso, uso diversas técnicas, estratégias e
                elementos de várias abordagens que melhor se adequam e
                contribuem para sua melhoria.
              </p>
            </CardContent>
          </Card>
          <MusicPlayer />
        </div>

        <video
          id="about-video"
          className="ml-20 hidden aspect-square drop-shadow-2xl transition-all lg:block"
          src={presentation}
          onClick={togglePlayPause}
          width="480"
          height="auto"
          loop
          autoPlay
          muted
        ></video>
      </div>
      <div className="h-20 md:h-20 lg:h-32"></div>
      <Footer />
    </>
  )
}
