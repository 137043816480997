import { useEffect, useState } from 'react'
import { Cards } from './components/Cards'
import { Comments } from './components/Comments'
import { Faq } from './components/Faq'
import { Footer } from './components/Footer'
import { Group } from './components/Group'
import { Header } from './components/Header'
import { Hero } from './components/Hero'
import { HowItWorks } from './components/HowItWorks'
import { Loading } from './components/Loading'

export function App() {
  const [loading, setLoading] = useState(true)
  const [initialLoad, setInitialLoad] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
      if (initialLoad) {
        setInitialLoad(false)
      }
    }, 5900)

    return () => clearTimeout(timer)
  }, [initialLoad])

  if (loading && initialLoad) {
    return <Loading />
  }

  return (
    <>
      <Header />
      <Hero />
      <Cards />
      <Group />
      <div className="mb-40">
        <HowItWorks />
      </div>
      <Faq />
      <Comments />
      <Footer />
    </>
  )
}
