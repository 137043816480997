import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from '@/components/ui/carousel'
import { Star } from 'lucide-react'
import { profile } from '../../assets/comments-page'

export function Comments() {
  const comments = [
    {
      comment:
        'Só tenho palavras maravilhosas para dizer sobre a Berta. Excelente profissional, educada, alegre, energia positiva todo o tempo e de uma atenção imensurável com teus clientes. Extremamente preocupada com o nosso bem estar, desempenha tua profissão com maestria e dedicação. Recomendo sem nenhuma sombra de dúvida!!',
      stars: 5,
      author: 'M. R.',
    },
    {
      comment:
        'Todas as características que imaginamos ideais a um psicólogo a Berta tem, boa ouvinte, com observações precisas e assertivas que nos fazem refletir, sempre muito respeitosa e sábia com as palavras, enfim, muito acolhedora, super recomendo.',
      stars: 5,
      author: 'J. M.',
    },
    {
      comment:
        'Berta entrou em minha vida como psicóloga há dois anos, em um momento muito delicado. Em nossas primeiras sessões, ela me ensinou a ver o momento difícil como um rio, em que as correntezas levam tudo, inclusive os sentimentos ruins. Até hoje eu uso essa analogia em situações sensíveis. E isso é a terapia para mim, sentir, refletir, entender, ressignificar e me conhecer a cada dia mais. Desejo que todas as pessoas entendam a importância e necessidade do processo terapêutico e que possam ser cuidadas por profissionais incríveis como Berta. Detalhe: todo fim de sessão tem um mimo para adoçar o dia. Eu amo.',
      stars: 5,
      author: 'I. F.',
    },
    {
      comment:
        'Berta é uma pessoa leve, sempre sorridente e acolhedora. Com ela, você se sente seguro para ser quem é, sem medo de ser julgada. Ela é simplesmente a melhor psicóloga que eu poderia ter!',
      stars: 5,
      author: 'L. L.',
    },
    {
      comment:
        'Berta é excelente profissional, logo de cara eu já gostei. Muito pontual, atenta aos detalhes e muito gentil. Só tenho a agradecer.',
      stars: 5,
      author: 'M. C.',
    },
    {
      comment:
        'Difícil explicar em palavras, pois ela tem me ajudado a entender vários contextos dolorosos e difíceis da minha vida. Berta é atenciosa, humana e acolhedora. Fica aqui a minha gratidão.',
      stars: 5,
      author: 'A. R.',
    },
    {
      comment:
        'Uma psicóloga incrível, desde a primeira consulta me senti querida, compreendida e menos ansiosa. Ela me ajudou a entender um luto muito complicado e na compreensão dos meus sintomas por causa da síndrome do pânico. Obrigada por tudo.',
      stars: 5,
      author: 'L. B.',
    },
  ]

  return (
    <div className="mx-[12.5%] flex h-[1200px] w-3/4 select-none flex-col items-center justify-center space-y-10 text-center text-zinc-600 transition-all md:h-[800px]">
      <Avatar className="flex h-40 w-40 items-center justify-center">
        <AvatarImage src={profile} alt="Foto de perfil" />
        <AvatarFallback>Berta</AvatarFallback>
      </Avatar>
      <p>
        Confira o que os pacientes têm a dizer sobre a minha prática clínica:
      </p>
      <Carousel className="w-[200px] text-xs md:w-96 md:text-base xl:w-[600px]">
        <CarouselContent>
          {comments.map((item, index) => (
            <CarouselItem key={index}>
              {item.comment}
              <div className="m-4 flex items-center justify-center">
                {[...Array(item.stars)].map((_, starIndex) => (
                  <Star key={starIndex} color="#FACC30" strokeWidth={3} />
                ))}
              </div>
              <p className="m-4">{item.author}</p>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
    </div>
  )
}
