import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import { gsap } from 'gsap'
import { Eye } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import { group, inPerson, onLine } from '../../assets/cards-page/index'
import JoinModal from './JoinModal/index'

export function Cards() {
  const inPersonCard = useRef()
  const onlineCard = useRef()
  const groupCard = useRef()
  const [inPersonFlipped, setInPersonFlipped] = useState(false)
  const [onlineFlipped, setOnlineFlipped] = useState(false)
  const [groupFlipped, setGroupFlipped] = useState(false)

  const handleInPersonCardClick = () => {
    setInPersonFlipped(!inPersonFlipped)
    flipCard(inPersonCard.current)
  }

  const handleOnlineCardClick = () => {
    setOnlineFlipped(!onlineFlipped)
    flipCard(onlineCard.current)
  }

  const handleGroupCardClick = () => {
    setGroupFlipped(!groupFlipped)
    flipCard(groupCard.current)
  }

  const flipCard = (cardRef) => {
    const tl = gsap.timeline()
    tl.to(cardRef, {
      rotationY: '180deg',
      duration: 0.5,
      ease: 'power2.inOut',
      onComplete: () => {
        if (cardRef === inPersonCard.current) {
          gsap.set(cardRef, { rotationY: '0deg' })
        } else if (cardRef === onlineCard.current) {
          gsap.set(cardRef, { rotationY: '0deg' })
        } else if (cardRef === groupCard.current) {
          gsap.set(cardRef, { rotationY: '0deg' })
        }
      },
    })
  }

  useEffect(() => {
    const tl = gsap.timeline({ defaults: { ease: 'power4.in' } })
    tl.to([inPersonCard.current, onlineCard.current, groupCard.current], {
      visibility: 'visible',
      display: 'block',
      opacity: 1,
      duration: 1,
    })
    tl.to('.title-section', {
      visibility: 'visible',
      display: 'block',
      opacity: 1,
      duration: 1,
    })
  }, [])

  return (
    <section id="iwanttostart">
      <div className="title-section opacity-0 transition-all">
        <p className="mx-[12.5%] my-40 flex w-3/4 items-center justify-center text-center text-zinc-600">
          Saúde emocional é para todos!
          <br />
          Que tipo de terapia você precisa?
        </p>
        <div className="mx-[12.5%] flex w-3/4 flex-col items-center justify-center space-y-10 md:-mt-20 md:flex-row md:space-x-10 md:space-y-0">
          {/* Card Presencial */}
          <Card
            className="h-[480px] w-[300px] cursor-pointer select-none overflow-clip opacity-0 drop-shadow-xl"
            ref={inPersonCard}
            onClick={handleInPersonCardClick}
          >
            <CardHeader>
              <CardTitle className="flex cursor-pointer select-none items-center justify-center text-zinc-700 underline-offset-4 hover:underline">
                Presencial
              </CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              {inPersonFlipped ? (
                <>
                  <p className="space-y-10 text-xs text-zinc-600 md:text-sm">
                    <strong>Conexão mais profunda:</strong>
                    <br />O contato presencial facilita uma conexão mais
                    profunda entre terapeuta e cliente.
                  </p>

                  <p className="text-xs text-zinc-600 md:text-sm">
                    <strong>Comunicação não verbal:</strong>
                    <br />
                    Permite a observação de expressões faciais e linguagem
                    corporal.
                  </p>

                  <p className="text-xs text-zinc-600 md:text-sm">
                    <strong>Ambiente seguro:</strong>
                    <br />
                    Proporciona um espaço seguro e acolhedor para explorar
                    questões emocionais.
                  </p>

                  <p className="text-xs text-zinc-600 md:text-sm">
                    <strong>Sensação de proximidade:</strong> <br />
                    Promove um ambiente de confiança e segurança, permitindo uma
                    comunicação mais aberta.
                  </p>
                </>
              ) : (
                <>
                  <img
                    src={inPerson}
                    alt="Imagem de pessoas conversando pessoalmente"
                    className="my-10"
                  />
                  <CardFooter>
                    <Button className="w-full bg-[#ABE3E1] hover:bg-[#EBBFEC]">
                      <Eye className="text-zinc-600" />
                      <p className="ml-4 text-zinc-600">Ver detalhes</p>
                    </Button>
                  </CardFooter>
                </>
              )}
            </CardContent>
          </Card>
          {/* Card Online */}
          <Card
            className="h-[480px] w-[300px] cursor-pointer select-none overflow-clip opacity-0 drop-shadow-xl"
            ref={onlineCard}
            onClick={handleOnlineCardClick}
          >
            <CardHeader>
              <CardTitle className="flex cursor-pointer select-none items-center justify-center text-zinc-700 underline-offset-4 hover:underline">
                On-line
              </CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              {onlineFlipped ? (
                <>
                  <p className="text-xs text-zinc-600 md:text-sm">
                    <strong>Comodidade no acesso:</strong>
                    <br />
                    Participação em sessões de terapia de qualquer lugar com
                    conexão à internet, eliminando deslocamentos.
                  </p>
                  <p className="text-xs text-zinc-600 md:text-sm">
                    <strong>Flexibilidade de horários:</strong>
                    <br />
                    Oferecemos agendamento flexível para sessões, com adaptação
                    à rotina diária dos paciente, especialmente para aqueles com
                    agendas lotadas.
                  </p>
                  <p className="text-xs text-zinc-600 md:text-sm">
                    <strong>Redução do estigma:</strong>
                    <br />
                    Menos intimidante que terapia presencial, reduzindo o
                    estigma da saúde mental e encorajando mais pessoas a buscar
                    ajuda.
                  </p>
                </>
              ) : (
                <>
                  <img
                    src={onLine}
                    alt="Imagem de pessoas conversando virtualmente"
                    className="my-10"
                  />
                  <CardFooter>
                    <Button className="w-full bg-[#ABE3E1] hover:bg-[#EBBFEC]">
                      <Eye className="text-zinc-600" />
                      <p className="ml-4 text-zinc-600">Ver detalhes</p>
                    </Button>
                  </CardFooter>
                </>
              )}
            </CardContent>
          </Card>
          {/* Card em Grupo */}
          <Card
            className="h-[480px] w-[300px] cursor-pointer select-none overflow-clip opacity-0 drop-shadow-xl"
            ref={groupCard}
            onClick={handleGroupCardClick}
          >
            <CardHeader>
              <CardTitle className="flex cursor-pointer select-none items-center justify-center text-zinc-700 underline-offset-4 hover:underline">
                Grupos
              </CardTitle>
            </CardHeader>
            <CardContent className="space-y-4">
              {groupFlipped ? (
                <>
                  <p className="text-xs text-zinc-600 md:text-sm">
                    <strong>Suporte entre pares:</strong>
                    <br />
                    Conexão com pessoas enfrentando desafios semelhantes,
                    proporcionando compreensão e apoio mútuo.
                  </p>

                  <p className="text-xs text-zinc-600 md:text-sm">
                    <strong>Diversidade de perspectivas:</strong>
                    <br />
                    Oportunidade de ouvir diferentes abordagens para lidar com
                    os desafios, levando a insights inovadores.
                  </p>

                  <p className="text-xs text-zinc-600 md:text-sm">
                    <strong>Terapia grupal de vivencia e saúde mental:</strong>
                    <br />
                    Convívio entre pacientes com problemas e transtornos
                    similares.
                  </p>

                  <p className="text-xs text-zinc-600 md:text-sm">
                    <strong>Desenvolvimento de habilidades sociais:</strong>
                    <br />
                    Prática de comunicação eficaz e resolução de conflitos,
                    serve para outras áreas da vida.
                  </p>
                </>
              ) : (
                <>
                  <img
                    src={group}
                    alt="Imagem de pessoas conversando virtualmente"
                    className="my-10"
                  />
                  <CardFooter>
                    <Button className="w-full bg-[#ABE3E1] hover:bg-[#EBBFEC]">
                      <Eye className="text-zinc-600" />
                      <p className="ml-4 text-zinc-600">Ver detalhes</p>
                    </Button>
                  </CardFooter>
                </>
              )}
            </CardContent>
          </Card>
        </div>
        <div className="m-10 mt-20 flex items-center justify-center">
          <JoinModal />
        </div>
      </div>
    </section>
  )
}
